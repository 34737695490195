import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => {
  console.info("404 page")

  return (
    <Layout>
      <Seo title="Loading..." />
    </Layout>
  )
}

export default NotFoundPage
